import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import logo from './logo.png';

const App = () => {
  const [topStories, setTopStories] = useState([]);
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [heroContent, setHeroContent] = useState(null); // New state for Hero Section
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const topStoriesResponse = await axios.get(
          `https://eventregistry.org/api/v1/article/getArticles?apiKey=${process.env.REACT_APP_API_KEY}&keyword=technology&lang=eng&articlesCount=9&keywordLoc=title`
        );
        const featuredArticlesResponse = await axios.get(
          `https://eventregistry.org/api/v1/article/getArticles?apiKey=${process.env.REACT_APP_API_KEY}&keyword=tech&lang=eng&articlesCount=30&keywordLoc=title`
        );
        const heroContentResponse = await axios.get(
          `https://eventregistry.org/api/v1/article/getArticles?apiKey=${process.env.REACT_APP_API_KEY}&keyword=breaking&lang=eng&articlesCount=1&keywordLoc=title`
        ); // Example API for hero content

        setTopStories(topStoriesResponse.data.articles.results);
        setFeaturedArticles(featuredArticlesResponse.data.articles.results);
        setHeroContent(heroContentResponse.data.articles.results[0]); // Set hero content
      } catch (err) {
        setError('Failed to fetch articles');
      }
    };

    fetchArticles();
  }, []);

  

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="app">
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
      </header>

      {/* Hero Section */}
      {heroContent && (
        <section className="hero">
          <img src={heroContent?.image} alt="Hero" className="hero-image" />
          <div className="hero-text">
            <h2>{heroContent.title}</h2>
            <p>{heroContent.summary}</p>
            <a href={heroContent.url} target="_blank" rel="noopener noreferrer" className="hero-btn">Read More</a>
          </div>
        </section>
      )}

      <div className="main-content">
        <section className="top-stories">
          <h2>Top Stories</h2>
          {topStories.map((article, index) => (
            <div className="story-card" key={index}>
              <img src={article?.image} alt='' className="story-image" />
              <h3>{article.title}</h3>
              <p>{article.summary}</p>
              <a href={article.url} target="_blank" rel="noopener noreferrer">
                Read more
              </a>
              <p className="time">Posted {article.date}</p>
            </div>
          ))}
        </section>

        <section className="featured-articles">
          <h3>Featured Articles</h3>
          {featuredArticles.map((article, index) => (
            <div key={index} className="featured-article">
              <a href={article.url} target="_blank" rel="noopener noreferrer">
                <h4>{article.title}</h4>
              </a>
            </div>
          ))}
        </section>

        <aside className="sidebar">
          <h4>Newsletter</h4>
          <form>
            <input type="email" placeholder="Email address" />
            <button type="submit">Join</button>
          </form>

          <h4>Other News</h4>
          <ul>
            {featuredArticles.map((article, index) => (
              <li key={index}>
                <a href={article.url} target="_blank" rel="noopener noreferrer">{article.title}</a>
              </li>
            ))}
          </ul>
        </aside>
      </div>

      <footer className="footer">
        <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a><br></br>
        <a href="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
        <p>© 2024 Newskylab. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
